import _ from 'lodash'
import { PAGE_LIMIT } from 'modules/pages/gamePlayers/constants'
import { IRewardsData } from '../type'

export function getUniqueObjects<T>(array: T[], property: keyof T): T[] {
  const uniqueObjects: T[] = []
  const uniqueValues = new Set<T[keyof T]>()
  for (const obj of array) {
    if (!obj) continue
    const value = obj[property]
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value)
      uniqueObjects.push(obj)
    }
  }
  return uniqueObjects
}

export function abbreviateNumber(value: number) {
  let newValue: any = value

  if (isNaN(newValue)) return 'N/A'

  if (value >= 1000) {
    let suffixes = ['', 'k', 'Million', 'Billion', 'Trillion']
    let suffixNum = Math.floor(('' + value).length / 3)
    suffixNum = suffixNum > 4 ? 4 : suffixNum
    let shortValue: any = ''
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      )
      let dotLessShortValue = String(shortValue).replace(/[^a-zA-Z0-9 ]+/g, '')

      if (dotLessShortValue.length <= 2) {
        break
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1)

    newValue = `$ ${shortValue}` + suffixes[suffixNum]
  } else {
    newValue = `$ ${newValue}`
  }

  return newValue ?? 'N/A'
}

export const isArrayEqual = function (x: any, y: any) {
  return _(x).xorWith(y, _.isEqual).isEmpty()
}

export function deepCompareArray(arr1: string[], arr2: string[]): boolean {
  // If the arrays have different lengths, they are not equal
  if (arr1?.length !== arr2?.length) {
    return false
  }

  // Recursively compare each element of the arrays
  for (let i = 0; i < arr1?.length; i++) {
    const item1 = arr1?.[i]
    const item2 = arr2?.[i]

    // If the items are arrays, recursively compare them
    if (Array.isArray(item1) && Array.isArray(item2)) {
      if (!deepCompareArray(item1, item2)) {
        return false
      }
    }
    // If the items are objects, recursively compare them
    else if (typeof item1 === 'object' && typeof item2 === 'object') {
      if (!deepCompareObject(item1, item2)) {
        return false
      }
    }
    // Compare the items directly
    else if (item1 !== item2) {
      return false
    }
  }

  // All elements are equal
  return true
}

// Helper function for deep comparing objects
export function deepCompareObject(obj1: any, obj2: any): boolean {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // If the objects have different numbers of keys, they are not equal
  if (keys1?.length !== keys2?.length) {
    return false
  }

  // Recursively compare each key-value pair in the objects
  for (const key of keys1) {
    const value1 = obj1?.[key]
    const value2 = obj2?.[key]

    // If the values are arrays, recursively compare them
    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (!deepCompareArray(value1, value2)) {
        return false
      }
    }
    // If the values are objects, recursively compare them
    else if (typeof value1 === 'object' && typeof value2 === 'object') {
      if (!deepCompareObject(value1, value2)) {
        return false
      }
    }
    // Compare the values directly
    else if (value1 !== value2) {
      return false
    }
  }

  // All key-value pairs are equal
  return true
}

export const calculatePercentage = (value: number, total: number): number =>
  (value / total) * 100

export function removeHttpsPrefixAndTrailingSlash(input: string): string {
  if (!input) return ''
  return input.replace(/^https:\/\/(.*?)\/?$/, '$1')
}

export const getPresignedUrlContentType = (contentType: string | undefined) => {
  switch (contentType) {
    case 'image/svg+xml':
      return 'SVG'
    case 'image/png':
      return 'PNG'
    case 'image/gif':
      return 'GIF'
    case 'image/jpeg':
      return 'JPEG'
    case 'image/webp':
      return 'WEBP'

    default:
      return null
  }
}

export const joinArrayWithSpace = (arr: (string | number)[]): string =>
  arr.map(String).join(' ')

export const getFrequencyStatus = (seconds: number) => {
  if (!seconds) return '--'

  const secondsInDay = 86400 // 24 * 60 * 60
  const secondsInWeek = 604800 // 7 * 24 * 60 * 60
  const secondsInMonth = 2592000 // Assuming a month has 30 days (30 * 24 * 60 * 60)

  switch (true) {
    case seconds <= secondsInDay:
      return 'Daily'
    case seconds <= secondsInWeek:
      return 'Weekly'
    case seconds <= secondsInMonth:
      return 'Monthly'
    default:
      return 'More than a month'
  }
}

export const findTotalSupply = (data: IRewardsData[], id: string) => {
  const num = data?.find((item: any) => item.id === id)?.total_supply
  if (num === undefined) return 0
  else return Number(num)
}

export function formatNumber(number: any) {
  if (number === undefined || number === null) return 0
  const num = Number(number)

  if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + 'B'
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + 'M'
  } else if (num >= 1e5) {
    return (num / 1e3).toFixed(1) + 'K'
  } else {
    return num.toString()
  }
}

export const removeStringsFromArray = (
  array: string[],
  stringsToRemove?: string[]
): string[] => {
  if (!array?.length) return []

  if (!stringsToRemove || stringsToRemove.length === 0) {
    return array.length > 0 ? array : []
  }

  const filteredArray = array.filter((item) => !stringsToRemove.includes(item))

  return filteredArray.length ? filteredArray : []
}

export const replaceKeysWithValues = async (
  array: string[],
  columnsMap: any
): Promise<string[] | null> => {
  if (!array?.length) return null

  const newArray: string[] = array.flatMap((item) => {
    if (columnsMap[item]) {
      return columnsMap[item] // Replace key with values if key is found in columnsMap
    }
    return item // Return the item unchanged if no match is found
  })

  return newArray.length ? newArray : null
}
export const convertDisplayToQuery = (
  displayQuery: any,
  displayToKeyMap: any
) => {
  let backendQuery = displayQuery
  displayToKeyMap.forEach((value: any, key: any) => {
    const regex = new RegExp(`\\b${key}\\b`, 'gi')
    backendQuery = backendQuery?.replace(regex, value)
  })
  return backendQuery
}

export const getNextSortOrder = (currentSortOrder: string) => {
  if (currentSortOrder === '') {
    return 'asc'
  } else if (currentSortOrder === 'asc') {
    return 'desc'
  } else {
    return ''
  }
}

export const getSortOrder = (
  currentSortOrder: string,
  ascendingOrder: string | boolean,
  descendingOrder: string | boolean
) => {
  if (currentSortOrder === '') {
    return { name: 'Highest', value: descendingOrder }
  } else if (currentSortOrder === 'asc') {
    return { name: 'Lowest', value: ascendingOrder }
  } else {
    return { name: 'Default', value: null }
  }
}

export const replaceKeysInObject = async (
  obj: any,
  keyMap: { [key: string]: string }
): Promise<any> => {
  if (!obj || typeof obj !== 'object') return null

  const newObj: any = {}

  Object.keys(obj).forEach((key) => {
    const newKey = keyMap[key] || key
    newObj[newKey] = obj[key]
  })

  return newObj
}

export const handlePageChange = (
  pageNumber: number,
  setPage: Function,
  filtersOptions: any,
  setFiltersOptions: Function
) => {
  setPage(pageNumber)
  setFiltersOptions({
    ...filtersOptions,
    page: pageNumber,
    limit: PAGE_LIMIT,
  })
}

export const numberInputOnWheelPreventChange = (e: any) => {
  // Prevent the input value change
  e.target.blur()

  // Prevent the page/container scrolling
  e.stopPropagation()

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus()
  }, 0)
}
